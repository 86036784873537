import React from 'react'
import "./footer.css"
export default function Footer() {
    return (
        <div className='footer-main'> 
            <div className='footer-in'>

                <div className='footer-p'>
                    <p>Privacy Policy </p>
                    <p>Terms of Use </p>
                </div>
                <div className='footer-p'>
                    <p> Copyright@Tajirai.com</p>

                </div>
            </div>

        </div>
    )
}
